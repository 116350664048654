<template>
    <modal ref="modalConsulta" titulo="Consulta" no-aceptar adicional="Enviar" @adicional="postConsulta">
        <ValidationObserver ref="form">
            <div class="row mx-0 j-center">
                <div class="col-8 pl-4 ml-3 text-general f-12">
                    Por medio del soporte podrás comunicarte <br /> directamente con {{$config.vendedor}}.
                </div>
                <div class="col-8 my-3">
                    <p class="text-general pl-3 f-12">
                        Cuéntanos ¿Qué consulta tienes?
                    </p>
                    <ValidationProvider v-slot="{ errors }" rules="required" name="¿Qué consulta tienes?">
                        <el-input v-model="model.mensaje" type="textarea" :rows="7" placeholder="¿Qué problema has tenido?" class="w-100 br-12" />
                        <span class="text-danger f-10">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="col-8 mt-3">
                    <p class="text-general pl-3 f-12 mb-1">
                        Adjuntar algunas evidencias
                    </p>
                    <div class="row mx-0 d-flex justify-content-around">
                        <slim-cropper ref="fotoReclamo1" :options="slimOptions" class="border cr-pointer" style="height:120px;width:120px;background:#F8F9FF;border-radius:4px;" />
                        <slim-cropper ref="fotoReclamo2" :options="slimOptions" class="border cr-pointer" style="height:120px;width:120px;background:#F8F9FF;border-radius:4px;" />
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Service from '@/services/ayuda/ayuda'

export default {
    data(){
        return {
            model:{
                mensaje: '',
            },
            
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
        }
    },
    methods: {
        toggle(){
            this.limpiar();
            this.$refs.modalConsulta.toggle();
        },

        async postConsulta(){
            try {
                let validate = await this.$refs.form.validate()
                if(!validate) return;

                this.model.evidencias = [
                    this.$refs.fotoReclamo1.instanciaCrop.dataBase64.output.image,
                    this.$refs.fotoReclamo2.instanciaCrop.dataBase64.output.image
                ].filter(el=>el);

                const {data} = await Service.postConsulta(this.model)
                if(data.exito){
                    this.notificacion('Mensaje', 'Consulta iniciada exitosamente', 'success');
                    this.$refs.modalConsulta.toggle();
                    this.$emit('update', data.consulta);
                }
            } catch(e){
                this.errorCatch(e)
            }
        },

        limpiar(){
            this.model.mensaje = null;
            this.$refs.fotoReclamo1.instanciaCrop.remove();
            this.$refs.fotoReclamo2.instanciaCrop.remove();
        },
    }
}
</script>
